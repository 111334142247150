import { apiClient } from '../index';

/**
 * [Crea una noticia]
 * @return {[type]}          [description]
 */
function create(formData) {
  return apiClient.post('/v1/rules', formData, {
    headers: {
    'Content-Type': 'multipart/form-data;',
    }
  });
}

/**
 * [Obtiene todas las noticias]
 * @return {[type]}          [description]
 */
 function fetch() {
  return apiClient.get('/v1/rules');
}

/**
 * [Switch el campo deleted true/false]
 * @return {[type]}          [description]
 */
function deleteRule(id) {
  return apiClient.delete('/v1/rules/delete/' + id );
}



export default { create, fetch, deleteRule };