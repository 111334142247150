<template>
  <div class="padding-card-c grey">
      <v-card class="padding-card">
          <v-row>
              <v-btn 
                  depressed 
                  color="#00aff2" 
                   @click="downloadPdf"
                  dark>PDF</v-btn>             
              <v-btn 
                  depressed 
                  style="margin-left: 10px;"
                  color="green"
                  @click="exportToCsv"
                >Exportar CSV</v-btn>
                <v-btn 
                  depressed 
                  style="margin-left: 10px;"
                  @click="goToCancel"
                  >Cancelar</v-btn>
          </v-row>
          <vue-html2pdf
              :show-layout="contentRendered"
              :float-layout="false"
              :enable-download="true"
              :preview-modal="true"
              :paginate-elements-by-height="1400"
              filename="socios_dzidzilha.pdf"
              :pdf-quality="2"
              :manual-pagination="false"
              pdf-format="letter"
              pdf-orientation="portrait"
              pdf-content-width="800px"
              @progress="onProgress($event)"
              @startPagination="startPagination()"
              @hasPaginated="hasPaginated()"
              ref="html2Pdf"
          >              
          <user-pdf        
              :dataRep="dataRep" 
              :fields="fields"
              @domRendered="domRendered()" 
              slot="pdf-content"/> 
          </vue-html2pdf>            
         
      </v-card>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import UserPdf from '@/components/reports/UserPdf';

export default {
props: {
  dataRep: {
    type: Array,
    default: () => [],
  },
  fields: {
      type: Array,
  }
},
components:{ VueHtml2pdf, UserPdf },
data: () => ({
  page: 1,
  payments: [],
  paymentPlans: [],
  planId: null,
  planSelected: null,
  contentRendered: false,
  progress: 0,
  generatingPdf: false,
  pdfDownloaded: false,   
}),
created() {
 console.log(this.dataRep);
},
computed: {   
  length() {
    return Math.ceil(this.payments.length / 10);
  },

},
methods: {   
  onProgress(progress) {
    this.progress = progress;
    console.log(`PDF generation progress: ${progress}%`)
  },
  startPagination() {
    console.log(`PDF has started pagination`)
  },
  hasPaginated () {
    console.log(`PDF has been paginated`)
  },    
  async downloadPdf() {
    var opt = {
          pagebreak: {
              mode: ['avoid-all', 'css', 'legacy']
          },
          margin:       [10, 10, 100, 10],
      };
    this.$refs.html2Pdf.generatePdf(opt);
  },
  goToCancel() {
    // Redirect to a specific route
    this.$router.push('/userreportform'); // Replace '/desired-path' with your actual route path
  },
  exportToCsv() {
      if (!this.dataRep || this.dataRep.length === 0) {
        alert('No data available for export.');
        return;
      }

      // Prepare CSV data
      const headers = this.fields.join(','); // Convert fields to CSV header row
      const rows = this.dataRep.map(row => {
        return this.fields.map(field => `"${row[field] || ''}"`).join(',');
      });
      const csvContent = [headers, ...rows].join('\n');

      // Create a Blob and trigger download
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);

      link.setAttribute('href', url);
      link.setAttribute('download', 'report.csv');
      link.style.visibility = 'hidden';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
 
},
}
</script>
<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>