<template>
    <v-simple-table style="margin:30px 5px">
        <thead>
            <th>#</th>
            <th class="text-left" v-for="(item, index) in fields" :key="index"> {{ titles[item] }} </th>
        </thead>
        <tbody>
            <tr v-for="(line, index) in dataRep" :key="index">
                <td style="font-size:18px">{{ index + 1 }}</td>
                <td class="text-left" v-for="(item, index) in line" :key="index" style="font-size:18px">
                    {{ item }}
                </td>
            </tr>
        </tbody>
    </v-simple-table>   
</template>

<script>
export default {
  props: {
    dataRep: {
      type: Array,
      default: () => [],
    },
    fields: {
        type: Array,
    }
  },  
  data: () => ({    
    titles: {
          lotPurchased: 'Usuario',  
          username: 'Propietario',
          lotenum: 'Lote',
          manzana: 'Manzana',
          street: 'Calle',
          placeOfResidence: 'Lugar residencia',
          mobilePhone: 'Celular',
          email: 'Correo',
          statusDebt: 'Status financiero',
          legalStatus: 'Status predio',
          contactName: 'Nombre contacto secundario',
          contactEmail: 'Correo contacto secundario',
          contactMobile: 'Celular contacto secundario',
          hasWhats: 'Incluido WhatsApp',
          numPets: 'Número mascotas',
          docuPets: 'Documentación mascotas',
          tag1: 'Tag1',
          tag2: 'Tag2',
          tag3: 'Tag3',
          debt1: '2021', debt2: '2022', debt3: '2023',
          debt4: '2024', debt5: '2025',
          sanctions: 'Sanciones', coments: 'Comentarios'          
    }
  }),
  created() {
   console.log(this.dataRep);
  },
}
</script>
<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>