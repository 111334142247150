<template>
  <div>
    <div style="display: flex; align-items: center; justify-content: center; padding: 50px 0px 50px 0px">      
    </div>
    <v-col v-for="rule in reglamentos" :key="rule.id" cols="12">
      <v-btn block color="primary" large rounded target="_blank" :href="rule.pdfName" >{{ rule.title }}</v-btn>      
    </v-col>    
    <v-col cols="12">  
      <v-btn block color="primary" rounded outlined link to="/regulations"
      >  Regresar</v-btn>
    </v-col> 
  </div>
</template>

<script>
import adminRuleService from '@/services/admin/rules';

export default {
  
  created() {
    this.getRules();   
  },
  methods: {
    getRules() {
      adminRuleService.fetch().then((response) => {
        this.rules = response.data;       
        this.reglamentos = this.rules.filter( r =>  r.kind == 'Reglamentos' );   
      });
    },
  },
  data() {
    return {          
      reglamentos: [],
      reg_uso: [],
      asamblea: []
    };
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>



