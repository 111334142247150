<template>
  <div>
    <div v-for="(item, i) in news" :key="i" style="padding-bottom: 25px">
      <v-card class="mx-auto rounded-lg">
        <v-card-title>
          <v-row align="center">
            <v-col cols="2">
              <v-avatar color="red">
                <v-img :src="item.author.imageUrl || imageDefaul"></v-img>
              </v-avatar>
            </v-col>
            <v-col cols="10">
              <v-list-item-content style="padding-left: 10px">
                <v-list-item-title style="font-weight: 600" v-html="item.author.username"> </v-list-item-title>
                <v-list-item-subtitle> {{$dayjs(item.createdAt).format('lll')}} </v-list-item-subtitle>
              </v-list-item-content>
            </v-col>
          </v-row>
        </v-card-title>       
        <v-card-subtitle class="title-card-event"> {{ item.title }} </v-card-subtitle>
        <v-card-text>
          {{ item.description }}
          <v-btn v-if="item.pdfUrl" 
            block color="primary" 
            small
            rounded
            target="_blank" 
            class="mt-4"
            :href="item.pdfUrl">ver pdf</v-btn>
        </v-card-text>
        
      </v-card>
    </div>
  </div>
</template>

<script>
import adminNewService from '@/services/admin/news';
export default {
  created() {
    this.getNews();
  },
  computed: {
    imageDefaul() {
      return require('@/assets/images/avatar_dz.jpg');
    },
  },

  methods: {
    getNews() {
      adminNewService.fetch().then((response) => {
        this.news = response.data.filter((item)=>!item.deleted);
      });
    },
  },

  data() {
    return {
      news: [],
    };
  },
};
</script>

<style lang="scss">
@import './src/assets/style.scss';
</style>
