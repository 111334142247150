 <template>
  <div>

    <p class="title-user-scr">Datos de la propiedad</p>  
    <v-row>   
      <v-col cols="4">
        <v-text-field 
          dense 
          :rules="rules.manzana"
          placeholder="99" 
          v-model="manzana" 
          maxlength="2"    
          minlength="2"     
          label="Manzana*"></v-text-field>
      </v-col>  
      <v-col cols="4">
        <v-text-field 
          dense 
          :rules="rules.manzana" 
          v-model="lotenum" 
          placeholder="99"
          maxlength="2"   
          minlength="2"        
          label="Lote*"></v-text-field>
      </v-col> 
      <v-col cols="4">
        <v-text-field 
          dense 
          :rules="rules.lonsm" 
          v-model="street" 
          maxlength="30" 
          label="Calle"></v-text-field>
      </v-col> 
      <v-col cols="4">
      <v-autocomplete
            dense
            item-text="title"            
            return-object    
            v-model="legalStatus"         
            :items="statusPredio"
            filled
            label="Status Predio"
          >
        </v-autocomplete>  
      </v-col>
    </v-row>


    <p class="title-user-scr">Datos del propietario titular</p> 
    <v-row>
      <v-file-input
        v-if="!isAdmin"
        ref="uploadImage"
        @change="selectFile"
        accept="image/*"
        label="Seleccionar una foto"
      ></v-file-input>

      <v-col cols="12">
        <v-text-field dense :rules="rules.name" v-model="username" label="Nombre completo*"></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          dense
          :rules="rules.mobileReq"
          v-model="mobilePhone"
          placeholder="999-999-9999"
          name="celular"
          label="Celular*"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field 
          dense 
          :rules="rules.email" 
          placeholder="correo@mail.com"
          v-model="email" label="Correo electrónico*"></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-switch
          v-model="hasWhats"
          label="Incluido en WhatsApp"
          hide-details
          inset
        ></v-switch>
      </v-col>
      <v-col cols="4">       
        <v-autocomplete
            dense
            item-text="title"            
            return-object
            v-model="statusDebt"
            :items="statusSocio"
            filled
            label="Status"
          >
        </v-autocomplete>  
      </v-col>
      <v-col cols="4">
        <v-text-field dense :rules="rules.lonsm" v-model="placeOfResidence" label="Lugar residencia"></v-text-field>
      </v-col>
    </v-row>

    <p class="title-user-scr">Acceso App</p>  
    <v-row>
      <v-col cols="12">
        <v-text-field 
          :disabled=true
          dense 
          v-model="usernameLogin"          
          label="Usuario"></v-text-field>
      </v-col>
      <v-col md="6">
        <v-text-field
          dense
          :rules="modeEdit && !password.length ? [] : [rules.required, rules.min]"
          :type="show1 ? 'text' : 'password'"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          v-model="password"
          label="Contraseña"
          @click:append="show1 = !show1"
        ></v-text-field>
      </v-col>
      <v-col md="6">
        <v-text-field
          dense
          :rules="modeEdit && !password.length ? [] : [rules.required, rules.min]"
          :type="show2 ? 'text' : 'password'"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          v-model="confirmPassword"
          label="Confirmar contraseña"
          @click:append="show2 = !show2"
        ></v-text-field>
      </v-col>
    </v-row>   

    <p class="title-user-scr">Datos contacto secundario</p>  
    <v-row>   
      <v-col cols="12">
        <v-text-field dense :rules="rules.lonmd" v-model="contactName" label="Nombre completo"></v-text-field>
      </v-col>      
      <v-col cols="6">
        <v-text-field
          dense      
          :rules="rules.mobile"    
          v-model="contactMobile"
          placeholder="999-999-9999"
          name="celular"
          label="Celular"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field 
          dense 
          :rules="rules.emailContact" 
          v-model="contactEmail" 
          placeholder="correo@mail.com"
          label="Correo electrónico"></v-text-field>
      </v-col>    
    </v-row>  

    <div v-if="isAdmin">
      <p class="title-user-scr">Control de accesos TAGS</p>  
      <v-row>      
        <v-col cols="4">
          <v-text-field dense :rules="rules.onlyNumbers" maxlength="30" v-model="tag1" label="Tag 1"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field dense :rules="rules.onlyNumbers" maxlength="30" v-model="tag2" label="Tag 2"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field dense :rules="rules.onlyNumbers" maxlength="30" v-model="tag3" label="Tag 3"></v-text-field>
        </v-col>  
      </v-row> 

      <p class="title-user-scr">Mascotas</p>  
      <v-row>     
        <v-col cols="4">
          <v-text-field dense v-model="numPets" type="number" maxlength="2" label="Número de mascotas"></v-text-field>
        </v-col> 
        <v-col cols="8">
          <v-text-field dense :rules="rules.lonmd" v-model="docuPets" label="Documentacion entregada"></v-text-field>
        </v-col>  
      </v-row>  

   
      <p class="title-user-scr">Situación financiera adeudo</p>  
        <v-row v-if="isAdmin">          
          <v-col cols="4"> 
            <v-text-field prefix="$" suffix="MXN" dense v-model="debt1" type="number" label="Adeudo 2021"></v-text-field>
          </v-col>
          <v-col cols="4">  
            <v-text-field prefix="$" suffix="MXN" dense v-model="debt2" type="number" label="Adeudo 2022"></v-text-field>
          </v-col>
          <v-col cols="4">  
            <v-text-field prefix="$" suffix="MXN" dense v-model="debt3" type="number" label="Adeudo 2023"></v-text-field>
          </v-col>
          <v-col cols="4">  
            <v-text-field prefix="$" suffix="MXN" dense v-model="debt4" type="number" label="Adeudo 2024"></v-text-field>
          </v-col>
          <v-col cols="4">  
            <v-text-field prefix="$" suffix="MXN" dense v-model="debt5" type="number" label="Adeudo 2025"></v-text-field>
          </v-col>
        </v-row> 

        <p class="title-user-scr">Otros datos</p>  
        <v-row>   
          <v-col cols="12">  
            <v-text-field dense :rules="rules.lonxl" v-model="coments" label="Comentarios"></v-text-field>
          </v-col>
          <v-col cols="12">  
            <v-text-field dense :rules="rules.lonlg" v-model="sanctions" label="Sanciones"></v-text-field>
          </v-col>  
        </v-row>       
    </div>

  </div>
</template>
 
<script>
export default {
  name: 'UserForm',
  props: {
    infoUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show1: false,
      show2: false,
      role: 'user',
      usernameLogin:'',
      username: '',
      email: '',
      lotPurchased: '',     
      mobilePhone: '',
      password: '',
      confirmPassword: '',  
      statusDebt: 'normal',   
      image: '',
      statusSocio: ['normal', 'moroso', 'en proceso de pago'], 
      rules: {
          required: (value) => !!value || 'Required.',
          name: [
            (v) => !!v || 'Nombre is required',
            (v) => (v && v.length <= 60) || 'Nombre debe ser menor a 60 caracteres',
            (v) => (v && v.length >= 2) || 'Nombre debe ser mayor a 2 caracteres',
          ],
          min: (v) => (v && v.length >= 8) || 'Min 8 characters',
          emailMatch: () => 'The email and password you entered don\'t match',

          email: [
            (v) => !!v || 'E-mail is required',
            (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
          ],    

          emailContact: [ (v) => !v || /.+@.+\..+/.test(v) || 'E-mail must be valid' ],
          lonsm: [ (v) => !v || v.length <= 30 || 'Nombre debe ser menor a 30 caracteres' ],
          lonmd: [ (v) => !v || v.length <= 60 || 'Nombre debe ser menor a 60 caracteres' ],
          lonlg: [ (v) => !v || v.length <= 100 || 'Nombre debe ser menor a 100 caracteres' ],
          lonxl: [ (v) => !v || v.length <= 255 || 'Nombre debe ser menor a 255 caracteres' ],
          mobileReq: [
            (v) => !!v || 'Celular requerido',
            (v) => !v || /^[0-9\-()]*$/.test(v) || 'Celular Invalido. caráteres permitidos: [ 9-0, - ]',
          ],
          mobile: [ (v) => !v || /^[0-9\-()]*$/.test(v) || 'Celular Invalido. caráteres permitidos: [ 9-0, - ]' ],
          onlyNumbers: [ (v) => !v || /^[0-9]*$/.test(v) || 'Solo números 0-9 son permitidos' ],
          onlyNumbersReq: [ 
            (v) => !!v || 'Requerido',
            (v) => !v || /^[0-9]*$/.test(v) || 'Solo números 0-9 son permitidos' 
          ],
          manzana:[ 
            (v) => !!v || 'Requerido',
            (v) => !v || /^[0-9]*$/.test(v) || 'Solo números 0-9 son permitidos', 
            (v) => (v && v.length >= 2) || 'Longitud 2 caratéres',
          ],
        },
      statusPredio: ['escriturado', 'financiado' ],
      lotenum: '',
      manzana: '',
      street: '',  
      legalStatus: '',   
      placeOfResidence: '',
      contactName:'',
      contactEmail:'',
      contactMobile:'',
      hasWhats: false,
      tag1:'',
      tag2:'',
      tag3:'',
      numPets:'',
      docuPets:'',
      debt1: 0,
      debt2: 0,
      debt3: 0,
      debt4: 0,
      debt5: 0,
      coments: '',
      sanctions: '',
      //deprecated
      address: '',
      workplace: '',
      officePhone: '',
      lotInitialDeb: '',
      maintInitialDeb: '',
    };
  },
  mounted() {
    if (this.infoUser.id) {
      this.role = this.infoUser.role;
      this.usernameLogin = this.infoUser.usernameLogin;
      this.username = this.infoUser.username;
      this.email = this.infoUser.email;
      this.lotPurchased = this.infoUser.lotPurchased;   
      this.statusDebt = this.infoUser.statusDebt;  
      this.mobilePhone = this.infoUser.mobilePhone;
      this.password = this.infoUser.password || '';
      this.confirmPassword = this.infoUser.confirmPassword || '';
      this.lotenum = this.infoUser.lotenum || '';
      this.manzana = this.infoUser.manzana || '';
      this.street = this.infoUser.street;
      this.legalStatus = this.infoUser.legalStatus;      
      this.placeOfResidence = this.infoUser.placeOfResidence;
      this.hasWhats = this.infoUser.hasWhats;
      this.contactName = this.infoUser.contactName;
      this.contactEmail = this.infoUser.contactEmail;
      this.contactMobile = this.infoUser.contactMobile;
      this.tag1 = this.infoUser.tag1;
      this.tag2 = this.infoUser.tag2;
      this.tag3 = this.infoUser.tag3;
      this.numPets = this.infoUser.numPets;
      this.docuPets = this.infoUser.docuPets;      
      this.debt1 = this.infoUser.debt1;
      this.debt2 = this.infoUser.debt2;
      this.debt3 = this.infoUser.debt3;
      this.debt4 = this.infoUser.debt4;
      this.debt5 = this.infoUser.debt5;
      this.coments = this.infoUser.coments;
      this.sanctions = this.infoUser.sanctions;
      //deprecated
      this.address = this.infoUser.address;
      this.workplace = this.infoUser.workplace;
      this.officePhone = this.infoUser.officePhone;
      this.lotInitialDeb = this.infoUser.lotInitialDeb;
      this.maintInitialDeb = this.infoUser.maintInitialDeb;
    }
  },
  watch: {
    infoUser(newValue) {
      if (newValue.id) {
        this.role = this.infoUser.role;
        this.usernameLogin = this.infoUser.usernameLogin;
        this.username = this.infoUser.username;
        this.email = this.infoUser.email;
        this.lotPurchased = this.infoUser.lotPurchased;    
        this.statusDebt = this.infoUser.statusDebt;     
        this.mobilePhone = this.infoUser.mobilePhone;
        this.password = this.infoUser.password || '';
        this.confirmPassword = this.infoUser.confirmPassword || '';
        this.lotenum = this.infoUser.lotenum || '';
        this.manzana = this.infoUser.manzana || '';
        this.street = this.infoUser.street;  
        this.legalStatus = this.infoUser.legalStatus;    
        this.placeOfResidence = this.infoUser.placeOfResidence;
        this.hasWhats = this.infoUser.hasWhats;
        this.contactName = this.infoUser.contactName;
        this.contactEmail = this.infoUser.contactEmail;
        this.contactMobile = this.infoUser.contactMobile;
        this.tag1 = this.infoUser.tag1;
        this.tag2 = this.infoUser.tag2;
        this.tag3 = this.infoUser.tag3;
        this.numPets = this.infoUser.numPets;
        this.docuPets = this.infoUser.docuPets;      
        this.debt1 = this.infoUser.debt1;
        this.debt2 = this.infoUser.debt2;
        this.debt3 = this.infoUser.debt3;
        this.debt4 = this.infoUser.debt4;
        this.debt5 = this.infoUser.debt5;
        this.coments = this.infoUser.coments;
        this.sanctions = this.infoUser.sanctions;  
        // deprecated   
        this.address = this.infoUser.address;
        this.workplace = this.infoUser.workplace;
        this.officePhone = this.infoUser.officePhone;
        this.lotInitialDeb = this.infoUser.lotInitialDeb;
        this.maintInitialDeb = this.infoUser.maintInitialDeb;  
      }
    },
    manzana(newValue) {
      this.usernameLogin = `DZ${newValue}${this.lotenum}`;
      this.lotPurchased = `DZ${newValue}${this.lotenum}`;
    },
    lotenum(newValue){
      this.usernameLogin = `DZ${this.manzana}${newValue}`;
      this.lotPurchased = `DZ${this.manzana}${newValue}`;
    }
  },
  computed: {
    modeEdit() {
      return this.infoUser.usernameLogin ? true : false;
    },
    isAdmin() {
      return this.$store.getters['session/user'].role === 'admin';
    },
  },
  methods: {
    selectFile(file) {
      this.image = file;
    },
    userFormData() {
      if (this.modeEdit) {
        const formData = new FormData();

        if (this.isAdmin) {
          formData.append('lotPurchased', this.lotPurchased);
        }
        if (this.image) {
          formData.append('image', this.image, this.image.name);
        }
        if (this.password) {
          formData.append('confirmPassword', this.confirmPassword);
          formData.append('password', this.password);
        }        

        formData.append('username', this.username);
        formData.append('email', this.email) || '';        
        formData.append('mobilePhone', this.mobilePhone) || '';
        formData.append('statusDebt', this.statusDebt) || '';
        formData.append('lotenum', this.lotenum) || '';
        formData.append('manzana', this.manzana) || '';
        formData.append('street', this.street) || '';
        formData.append('legalStatus', this.legalStatus) || '';
        formData.append('lotenum', this.lotenum) || '';
        formData.append('placeOfResidence', this.placeOfResidence) || '';
        formData.append('hasWhats', this.hasWhats) || '';
        formData.append('contactName', this.contactName) || '';
        formData.append('contactEmail', this.contactEmail) || '';
        formData.append('contactMobile', this.contactMobile) || '';
        formData.append('tag1', this.tag1) || '';
        formData.append('tag2', this.tag2) || '';
        formData.append('tag3', this.tag3) || '';
        formData.append('numPets', this.numPets) || 0;
        formData.append('docuPets', this.docuPets) || '';
        formData.append('debt1', this.debt1) || 0;
        formData.append('debt2', this.debt2) || 0;
        formData.append('debt3', this.debt3) || 0;
        formData.append('debt4', this.debt4) || 0;
        formData.append('debt5', this.debt5) || 0;
        formData.append('coments', this.coments) || '';
        formData.append('sanctions', this.sanctions) || '';  
        // deprecated
        formData.append('address', this.infoUser.address) || ''; 
        formData.append('workplace', this.infoUser.workplace) || ''; 
        formData.append('officePhone', this.infoUser.officePhone) || ''; 
        formData.append('lotInitialDeb', this.infoUser.lotInitialDeb) || 0; 
        formData.append('maintInitialDeb', this.infoUser.maintInitialDeb) || 0;       
        
        return {
          id: this.infoUser.id,
          user: formData,
        };
      }
      return null;
    },
    userData() {
      if (this.modeEdit) {
        const user = {
            username: this.username,
            lotPurchased: this.lotPurchased,  
            statusDebt: this.statusDebt,          
            mobilePhone: this.mobilePhone || '',            
            email: this.email || '',
            lotenum: this.lotenum || '00',
            manzana: this.manzana || '00',
            street: this.street || '',
            legalStatus: this.legalStatus || '',
            placeOfResidence: this.placeOfResidence || '',
            hasWhats: this.hasWhats || '',
            contactName: this.contactName || '',
            contactEmail: this.contactEmail || '',
            contactMobile: this.contactMobile || '',
            tag1: this.tag1 || '',
            tag2: this.tag2 || '',
            tag3: this.tag3 || '',
            numPets: this.numPets || 0,
            docuPets: this.docuPets || '',
            debt1: this.debt1 || 0,
            debt2: this.debt2 || 0,
            debt3: this.debt3 || 0,
            debt4: this.debt4 || 0,
            debt5: this.debt5 || 0,
            coments: this.coments || '',
            sanctions: this.sanctions || '',
            // deprecated   
            address: this.infoUser.address || '',
            workplace: this.infoUser.workplace || '',
            officePhone: this.infoUser.officePhone || '',
            lotInitialDeb: this.infoUser.lotInitialDeb || 0,
            maintInitialDeb: this.infoUser.maintInitialDeb || 0,            
          };

        if (this.password) {
        user.password = this.password;
        user.confirmPassword = this.confirmPassword;
        }
        return {
          id: this.infoUser.id,
          user,
        };
      }
      return {
        role: this.role,
        usernameLogin: this.usernameLogin,
        username: this.username,
        email: this.email,
        lotPurchased: this.lotPurchased,  
        statusDebt: this.statusDebt || '',     
        mobilePhone: this.mobilePhone || '',
        password: this.password,
        confirmPassword: this.confirmPassword,
        lotenum: this.lotenum || '',
        manzana: this.manzana || '',
        street: this.street || '',
        legalStatus: this.legalStatus || '',
        placeOfResidence: this.placeOfResidence || '',
        hasWhats: this.hasWhats || '',
        contactName: this.contactName || '',
        contactEmail: this.contactEmail || '',
        contactMobile: this.contactMobile || '',
        tag1: this.tag1 || '',
        tag2: this.tag2 || '',
        tag3: this.tag3 || '',
        numPets: this.numPets || 0,
        docuPets: this.docuPets || '',
        debt1: this.debt1 || 0,
        debt2: this.debt2 || 0,
        debt3: this.debt3 || 0,
        debt4: this.debt4 || 0,
        debt5: this.debt5 || 0,
        coments: this.coments || '',
        sanctions: this.sanctions || '',  
        // deprecated   
        address: this.infoUser.address || '',
        workplace: this.infoUser.workplace || '',
        officePhone: this.infoUser.officePhone || '',
        lotInitialDeb: this.infoUser.lotInitialDeb || 0,
        maintInitialDeb: this.infoUser.maintInitialDeb || 0,        
      };
    },

    checkPassword () {
    if (this.password || this.confirmPassword) {
      if (this.password !== this.confirmPassword ) {
      return false;
      }
      return true;
    }
    },
  }
  
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>