<template>
  <v-form ref="formNews" v-model="validForm" lazy-validation>
    <v-text-field label="Título del boletín" v-model="title"></v-text-field>
    <v-textarea label="Texto de la publicación" v-model="description" outlined name="input-7-4"></v-textarea>
    <v-row>   
      <v-file-input ref="uploadPdf" @change="selectPdf" accept="application/pdf" label="Seleccionar Pdf"></v-file-input>
    </v-row>
    <v-btn @click="alertnewsletterpublish" depressed color="#00aff2" dark> Publicar </v-btn>
  </v-form>
</template>

<script>
import adminNewService from '@/services/admin/news';

export default {
  data() {
    return {
      validForm: true,
      news: [],
      title: '',
      description: '',
      pdf: null,
    };
  },
  methods: {
    selectFile(file) {
      this.image = file;
    },
    selectPdf(file) {
      this.pdf = file;
    },
    alertnewsletterpublish() {
      if (this.$refs.formNews.validate()) {
        const formData = new FormData();
        formData.append('title', this.title);
        formData.append('description', this.description);              
        if (this.pdf !== null && this.pdf != undefined && this.pdf != '') formData.append('pdf', this.pdf, this.pdf.name);
        adminNewService
          .create(formData)
          .then(() => {
            this.title = '';
            this.description = '';
            this.image = null;
            this.pdf = null;
            this.$emit('oncreate');
            this.$swal('Boletín publicado', 'El boletín se ha publicado con éxito', 'success');
            this.$emit('exit', true);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              this.$swal('Error al crear publicación', error.response.data.message, 'warning');
            }
            if (error.response && error.response.status === 413) {
              this.$swal('Error al crear publicación', error.response.data.message, 'warning');
            }
          });
      }
    },
  },
};
</script>