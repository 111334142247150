 <template>
  <div>
    <v-row>
      <v-col cols="4"  class="mt-4">
        <v-text-field
          :disabled="modeEdit" 
          dense 
          v-model="usernameLogin" 
          label="Usuario"></v-text-field>
      </v-col>
      <v-col cols="8"  class="mt-4">
        <v-text-field 
          dense          
          :disabled="!isAdmin"        
          v-model="username"           
          label="Nombre completo"></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          dense        
          :disabled="!isAdmin"          
          v-model="manzana"
          label="manzana"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          dense
          :disabled="!isAdmin"         
          v-model="lotenum"
          label="Lote"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          dense
          :disabled="!isAdmin"         
          v-model="street"
          label="Calle"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          dense
          :rules="rules.mobileReq"
          v-model="mobilePhone"
          placeholder="999-999-9999"
          name="celular"
          label="Celular*"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field 
          dense 
          placeholder="correo@mail.com"
          :rules="rules.email" 
          v-model="email" 
          label="Correo electrónico*"></v-text-field>
      </v-col>
      <v-col md="6">
        <v-text-field
          dense
          :rules="modeEdit && !password.length ? [] : [rules.required, rules.min]"
          :type="show1 ? 'text' : 'password'"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          v-model="password"
          label="Contraseña"
          @click:append="show1 = !show1"
        ></v-text-field>
      </v-col>
      <v-col md="6">
        <v-text-field
          dense
          :rules="modeEdit && !password.length ? [] : [rules.required, rules.min]"
          :type="show2 ? 'text' : 'password'"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          v-model="confirmPassword"
          label="Confirmar contraseña"
          @click:append="show2 = !show2"
        ></v-text-field>
      </v-col>     
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-text-field dense :rules="rules.lonmd" v-model="contactName" label="Nombre contacto"></v-text-field>
      </v-col>      
      <v-col cols="6">
        <v-text-field
          dense        
          :rules="rules.mobile" 
          v-model="contactMobile"
          placeholder="999-999-9999"
          name="celular"
          label="Celular contacto"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field dense :rules="rules.emailContact" v-model="contactEmail" label="Correo contacto"></v-text-field>
      </v-col>  
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-text-field
          dense
          :disabled="!isAdmin"         
          v-model="numPets"
          label="Número de mascotas"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          dense
          :disabled="!isAdmin"        
          v-model="docuPets"
          label="Documentación mascotas"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4">
        <v-text-field
          dense
          :disabled="!isAdmin"         
          v-model="debt1"
          label="Adeudo 2021"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          dense
          :disabled="!isAdmin"         
          v-model="debt2"
          label="Adeudo 2022"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          dense
          :disabled="!isAdmin"         
          v-model="debt3"
          label="Adeudo 2023"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          dense
          :disabled="!isAdmin"         
          v-model="debt4"
          label="Adeudo 2024"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          dense
          :disabled="!isAdmin"         
          v-model="debt5"
          label="Adeudo 2025"
        ></v-text-field>
      </v-col>
      
    </v-row>

  </div>
</template>
 
<script>
export default {
  name: 'UserForm',
  props: {
    infoUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show1: false,
      show2: false,
      role: 'user',
      usernameLogin:'',
      username: '',
      manzana: '',
      lotenum: '',  
      street: '',  
      email: '',    
      mobilePhone: '',
      numPets: 0,
      docuPets: '',
      debt1: 0,
      debt2: 0,
      debt3: 0,
      debt4: 0,
      debt5: 0,    
      password: '',
      confirmPassword: '',      
      image: '',
      statusSocio: ['normal', 'moroso'],
      rules: {
        required: (value) => !!value || 'Required.',
       
        name: [
          (v) => !!v || 'Nombre is required',
          (v) => (v && v.length <= 60) || 'Nombre debe ser menor a 60 caracteres ',
          (v) => (v && v.length >= 2 )|| 'Nombre debe ser mayor a 2 caracteres ',
        ],
        min: (v) => (v && v.length >= 8) || 'Min 8 characters',
        emailMatch: () => `The email and password you entered don't match`,
        email: [(v) => !!v || 'E-mail is required', (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
        mobileReq: [
            (v) => !!v || 'Celular requerido',
            (v) => !v || /^[0-9\-()]*$/.test(v) || 'Celular Invalido. caráteres permitidos: [ 9-0, - ]',
          ],
        mobile: [ (v) => !v || /^[0-9\-()]*$/.test(v) || 'Celular Invalido. caráteres permitidos: [ 9-0, - ]'],
        lonmd: [ (v) => !v || v.length <= 60 || 'Nombre debe ser menor a 60 caracteres' ],
        emailContact: [ (v) => !v || /.+@.+\..+/.test(v) || 'E-mail must be valid' ],
      },
      contactName:'',
      contactEmail:'',
      contactMobile:'',
    };
  },
  mounted() {
    if (this.infoUser.id) {
      this.role = this.infoUser.role;
      this.usernameLogin = this.infoUser.usernameLogin;
      this.username = this.infoUser.username;
      this.email = this.infoUser.email;     
      this.mobilePhone = this.infoUser.mobilePhone;
      this.password = this.infoUser.password || '';
      this.confirmPassword = this.infoUser.confirmPassword || '';
      this.manzana = this.infoUser.manzana || '';
      this.lotenum = this.infoUser.lotenum || '';
      this.street = this.infoUser.street || '';
      this.contactName = this.infoUser.contactName;
      this.contactEmail = this.infoUser.contactEmail;
      this.contactMobile = this.infoUser.contactMobile;
      this.numPets = this.infoUser.numPets,
      this.docuPets = this.infoUser.docuPets,
      this.debt1 = this.infoUser.debt1,
      this.debt2 = this.infoUser.debt2,
      this.debt3 = this.infoUser.debt3,
      this.debt4 = this.infoUser.debt4,
      this.debt5 = this.infoUser.debt5  
    }
  },
  watch: {
    infoUser(newValue) {
      if (newValue.id) {
        this.role = this.infoUser.role;
        this.usernameLogin = this.infoUser.usernameLogin;
        this.username = this.infoUser.username;
        this.email = this.infoUser.email;       
        this.mobilePhone = this.infoUser.mobilePhone;
        this.password = this.infoUser.password || '';
        this.confirmPassword = this.infoUser.confirmPassword || '';
        this.manzana = this.infoUser.manzana || '';
        this.lotenum = this.infoUser.lotenum || '';
        this.street = this.infoUser.street || '';
        this.contactName = this.infoUser.contactName;
        this.contactEmail = this.infoUser.contactEmail;
        this.contactMobile = this.infoUser.contactMobile;
        this.numPets = this.infoUser.numPets,
        this.docuPets = this.infoUser.docuPets,
        this.debt1 = this.infoUser.debt1,
        this.debt2 = this.infoUser.debt2,
        this.debt3 = this.infoUser.debt3,
        this.debt4 = this.infoUser.debt4,
        this.debt5 = this.infoUser.debt5  
      }
    },
  },
  computed: {
    modeEdit() {
      return this.infoUser.usernameLogin ? true : false;
    },
    isAdmin() {
      return this.$store.getters['session/user'].role === 'admin';
    },
  },
  methods: {
    selectFile(file) {
      this.image = file;
    },
    userFormData() {
      if (this.modeEdit) {
        const formData = new FormData();

        if (this.isAdmin) {
          formData.append('lotPurchased', this.lotPurchased);
        }
        if (this.image) {
          formData.append('image', this.image, this.image.name);
        }
        if (this.password) {
          formData.append('confirmPassword', this.confirmPassword);
          formData.append('password', this.password);
        }       
        formData.append('email', this.email) || '';     
        formData.append('mobilePhone', this.mobilePhone) || '';        
        formData.append('contactName', this.contactName) || '';
        formData.append('contactEmail', this.contactEmail) || '';
        formData.append('contactMobile', this.contactMobile) || '';
       
        return {
          id: this.infoUser.id,
          user: formData,
        };
      }
      return null;
    },
    userData() {
      if (this.modeEdit) {
        const user = {
            username: this.username,            
            mobilePhone: this.mobilePhone || '',           
            email: this.email || '',
            manzana: this.manzana || '',
            lotenum: this.lotenum || '',
            street: this.street || '',
            contactName: this.contactName || '',
            contactEmail: this.contactEmail || '',
            contactMobile: this.contactMobile || '',
          };
        if (this.password) {
        user.password = this.password;
        user.confirmPassword = this.confirmPassword;
        }
        return {
          id: this.infoUser.id,
          user,
        };
      }
      return {
        role: this.role,
        usernameLogin: this.usernameLogin,
        username: this.username,
        manzana: this.manzana,
        lotenum: this.lotenum,
        street: this.street,
        email: this.email,      
        mobilePhone: this.mobilePhone || '',
        password: this.password,
        confirmPassword: this.confirmPassword,               
        contactName: this.contactName || '',
        contactEmail: this.contactEmail || '',
        contactMobile: this.contactMobile || '',
      };
    },

    checkPassword () {
    if (this.password || this.confirmPassword) {
      if (this.password !== this.confirmPassword ) {
      return false;
      }
      return true;
    }
    },
  },
};
</script>
<style>
.theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
    color: rgba(0, 0, 0, 1) !important;
}
</style>


<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>


