<template>
    <v-dialog v-model="dialog" persistent max-width="1050px">
      <template v-slot:activator="{ on, attrs }">
          <v-btn color="white" small fab v-bind="attrs" v-on="on" height="30px" width="30px">
            <v-icon color="primary">mdi-account-edit</v-icon>
          </v-btn>
      </template>
      <v-form ref="form" v-model="validForm" lazy-validation>
      <v-card>
        <div class="padding-card">
          <p class="title-modal">Editar usuario</p>
          <UserForm v-if="dialog" ref="editUser" :info-user="infoUser" @ready-to-save="readyToSave = $event" />
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Cerrar
          </v-btn>
          <v-btn :disabled="!validForm" @click="validate" color="blue darken-1" text>
            Actualizar usuario
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>
</template>

<script>
import UserForm from "@/components/UserForm.vue";

export default {
  name: 'ModalUser',
  components: {
    UserForm,
  },
  props: {
    infoUser: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true      
    }
  },
  data() {
    return {
      validForm: true,
      dialog: false,
      readyToSave: false,
    }
  },

  methods: {
    close() {
      this.dialog = false;
      this.$refs.form.reset();
    },
    validate() {
      if (this.$refs.form.validate()) {
        const user = this.$refs.editUser.userData();
        console.log(this.index);
        this.$emit('save', user, this.index);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>